<template>
  <n-modal v-bind="$props">
    <!-- prettier-ignore -->
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <!-- id -->
        <n-field-id v-model="model.id" field-name="id" label="id" :error="error.id" :disabled="true" />
        <!-- type -->
        <n-field-enum v-model="model.type" field-name="type" enum-name="CustomerProfileType" label="type" :error="error.type" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- countryId -->
        <n-field-selection v-model="model.countryId" field-name="countryId" selection-name="market.country" :error="error.countryId" label="country" :disabled="isEdit || disabled" />
        <!-- groupId -->
        <ns-group v-model="model.groupId" field-name="groupId" :company-id="model.companyId" :error="error.groupId" label="group" :disabled="disabled" />
      </n-row-col2>
    </Form>
  </n-modal>
</template>
<script>
import { DATE_FORMAT } from '@/constant/format';
import NModal from '@/components/common/NModal';
import { add, edit, validate } from '@/api/company/company-allocation';

export default NModal.extend({
  name: 'ModalCompanyAllocation',
  components: { NModal },

  data() {
    return {
      DATE_FORMAT,
    };
  },

  methods: {
    doSubmit(model) {
      return this.isEdit ? edit(model) : add(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },
});
</script>
