<template>
  <index-content :breadcrumb-items="breadcrumbItems">
    <template #options>
      <div class="n-button-box">
        <nb-add @on-click="openModal" />
      </div>
    </template>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <th class="col-weight-2">{{ $t('label.id') }}</th>
          <th class="col-weight-2">{{ $t('label.company') }}</th>
          <th class="col-weight-2">{{ $t('label.type') }}</th>
          <th class="col-weight-3">{{ $t('label.country') }}</th>
          <th class="col-weight-4">{{ $t('label.group') }}</th>
          <th v-table-action class="action-edit">{{ $t('label.action') }}</th>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id }}</td>
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td v-enums:CustomerProfileType="row.type"></td>
            <td v-selection:[`market.country`]="row.countryId"></td>
            <td class="n-ellipsis">
              <n-modal-link name="company.group" :value="row.groupId" selection />
            </td>
            <td class="n-button-box"><nb-modal @on-click="openModal(row)"></nb-modal></td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import BaseCompanyView from './view';
import { getSelection } from '@/store';
import ModalCompanyAllocation from './ModalCompanyAllocation';
import { fetch, remove } from '@/api/company/company-allocation';

export default BaseCompanyView.extend({
  name: 'CompanyCompanyAllocation',

  data() {
    return {
      preload: true,
    };
  },

  mounted() {
    this.companyId = +this.$route.params.companyId;
    let companyName = getSelection('company.company', this.companyId);
    this.breadcrumbItems = [companyName, this.$t(`label.allocations`)];
  },

  methods: {
    doLoad() {
      let companyId = this.companyId;
      return fetch({ companyId });
    },

    doRemove(o) {
      return remove(o);
    },

    openModal(model = { companyId: this.companyId }) {
      this.createModal(ModalCompanyAllocation, { model, props: { subName: 'allocation' }, on: this });
    },

    parse(r) {
      this.records = r;
    },
  },
});
</script>
