import { $fetch, $post, $put, $delete } from '@/services/axios';

export function validate(data) {
  return $post(`company/allocation/validate`, data);
}

export function add(data) {
  return $put(`company/allocation`, data);
}

export function fetch(data) {
  return $fetch(`company/${data.companyId}/allocations`);
}

export function edit(data) {
  return $post(`company/allocation/${data.id}`, data);
}

export function remove(data) {
  return $delete(`company/allocation/${data.id}/${data.version}`);
}
